import '../App.css'
import { Desktop } from './Desktop'


// Import the functions you need from the SDKs you need
import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, query, newPost} from "../Firebase/Firebase";
import { NavLink } from "react-router-dom"
import { orderBy } from 'firebase/firestore';
import LoadingAnimation from '../Components/Loading'


const UserPage = (props) => {
  const [collectionData, setCollectionData] = useState([]);
  const [user] = useState(props.user);

  const fetchCollection = async () => {
    // Specify the collection to fetch
    const collectionRef = collection(db, "logbooks", "pobbof", "logEntrieID")
    try {
      // Fetch the collection
      const q = query(collectionRef, orderBy('index', 'asc'));
      
      const querySnapshot = await getDocs(q);
      // Extract the documents from the collection
      const documents = querySnapshot.docs.map((doc) => [doc.id, doc.data()]);
      // Update the state with the fetched collection
      setCollectionData(documents);

    } catch (error) {
      console.error('Error fetching collection:', error);
    }
  };

  useEffect(() => {
      fetchCollection()
    }, [user]);

  return (
    <Desktop>
    <div id='editBtns'>
        <button style={{margin: '20px 0 10px 0', padding:'10px'}} onClick={() => {newPost(user); fetchCollection()}}><b>Nytt inlägg</b></button>
    </div>
        <div id='logsListContainer'>
        {collectionData.length ? (
          collectionData.map((document, index) => (
            <div id='logs' key={index} >
              <NavLink to="/TextEditor">
                <li id="navLink" onClick={() => {props.setLogID(document[0])}} >{document[1].title}</li>
              </NavLink>
            { document[1].published ?
                  <label id="publish" style={{color: "green"}}>
                  { document[1].published === "about" ?
                    <p style={{color: "black", fontWeight: "bold"}}>Förord</p>
                    :
                    <p>Publiserad</p>
                  }
                  </label>
            : 
                  <label id="publish" style={{color: "red"}}> <p>Ej publiserad</p></label>
            }
            </div>
          ))
        ) : (
          <LoadingAnimation/>
        )}
        </div>
    </Desktop>
  );
};

export default UserPage;


