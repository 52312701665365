// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import config from "../firebaseConfig.json";

import { getAuth,
         signInWithEmailAndPassword,
         signOut, } from "firebase/auth"

import { getFirestore,
         doc, 
         getDoc,
         getDocs,
         collection,
         query, 
         where,
         setDoc,
         updateDoc,
         orderBy
      } from "firebase/firestore"

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.storageBucket,
  appId: config.appId,
  measurementId: config.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const signOutUser = () => {
  signOut(auth);
};


export {
  query, where,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  auth,
  db,
  logInWithEmailAndPassword,
  signOutUser,
};


export const savePost = async ( currentPost, title, text, index, publish, hookText, props) => {
  // Initialize Firestore

  // Specify the collection and document ID
  const t = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  const collectionRef = collection(db, "logbooks", 'pobbof', "logEntrieID");
  const documentRef = doc(collectionRef, currentPost);

  // Data to be saved or updated
  const data = {
    index: index,
    title: title,
    text: text,
    published: publish,
    hookText: hookText
  };

  try {
    // Save or update the document
    await setDoc(documentRef, data);
    // setLastSaved(timeStamp)
    props.setLastSaved(t)
    console.log('Save successfully!');
  } catch (error) {
    console.error('Error saving or updating document:', error);
  }
};

export const newPost = async () => {
  // Initialize Firestore

  // Specify the collection and document ID
  const collectionRef = collection(db, "logbooks", "pobbof", "logEntrieID");
  const documentRef = doc(collectionRef);

  // Data to be saved or updated
  const data = {
    index: 1,
    title: 'Nytt inlägg',
    text: "Text..",
    published: false,
    hookText: ""

  };

  try {
    // Save or update the document
    await setDoc(documentRef, data);
    console.log('Document saved or updated successfully!');
  } catch (error) {
    console.error('Error saving or updating document:', error);
  }
};

export const updateAbout = async ( aboutText, trait1, trait2, trait3, username) => {
  // Initialize Firestore

  // Specify the collection and document ID
  const docRef = doc(db, 'users', 'pobbof');

  // Data to be saved or updated
  const data = {
    about: aboutText,
    trait1: trait1,
    trait2: trait2,
    trait3: trait3,
    username: username,
    visibility: 'public',
  };

  try {
    // Save or update the document
    await setDoc(docRef, data);

    console.log('Document saved or updated successfully!');
  } catch (error) {
    console.error('Error saving or updating document:', error);
  }
};

export const publishPost = async ( publish, postID) => {
  // Initialize Firestore

  // Specify the collection and document ID
  const collectionRef = collection(db, "logbooks",'pobbof', "logEntrieID");
  const documentRef = doc(collectionRef, postID);

  // Data to be saved or updated
  const data = {
    published: publish
  };

  try {
    // Save or update the document
    await updateDoc(documentRef, data);

    console.log('Document saved or updated successfully!');
  } catch (error) {
    console.error('Error saving or updating document:', error);
  }
};



