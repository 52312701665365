import '../App.css'

import styled from 'styled-components'

export const Desktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  display: grid;
  grid-template-columns: 50% 40%;

  width: 100vw;
  height: calc(100vh - var(--headerNavHeight));
  margin-top: var(--headerNavHeight);


`