import React, { useState, useEffect } from 'react';
import '../App.css'
import styled from 'styled-components'

import { db, getDoc, doc, updateAbout } from "../Firebase/Firebase";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../TextEditor/TextEditor.css';

export const TextEditorContainer = styled.div`
  width: 100vw;
  height: calc(100vh - var(--headerNavHeight));
  margin-top: var(--headerNavHeight);
  
  form {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0 30px 0 ;
    label {
      font-size: 2rem
    }
    #titleInput {
      height: 2rem;
      font-size: 2rem
    }
    #checkbox {
      height: 25px;
      width: 25px
    }
  }
`

function TextEditor(props) {
  const [user] = useState(props.user);
  const [logID] = useState(props.logID);
  const [trait1, setTrait1] = useState("");
  const [trait2, setTrait2] = useState("");
  const [trait3, setTrait3] = useState("");
  const [username, setUsername] = useState("");

//   const handleCheckboxChange = (event) => {
//     console.log(publish);
//     setPublish(event.target.checked);
//   };

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    const fetchCollection = async () => {
      // Specify the collection to fetch
      const docRef = doc(db, "users", `${user}`);
      try {
        // Fetch the collection
        const docSnapshot = await getDoc(docRef);
        
        // Extract the documents from the collection
        const documents = docSnapshot.data();


        const contentBlocks = htmlToDraft(documents.about);
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        // Set the converted content state to the Draft.js editor
        const editorState = EditorState.createWithContent(contentState);

        // Update the state with the fetched collection
        setEditorState(editorState)
        // setPublish(documents.published)
        setTrait1(documents.trait1)
        setTrait2(documents.trait2)
        setTrait3(documents.trait3)
        setUsername(documents.username)
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };
    fetchCollection()
  }, [logID, user]);

  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html);
  }, [editorState]);
  
  // console.log(user.uid);
  

  return (
    
    <TextEditorContainer>
    <h1>About: {username}</h1>
        <form>
            <div>
                <label>Egenskap 1</label>
                <input
                    id='titleInput'
                    autoComplete=""
                    type="text"
                    value={trait1}
                    onChange={(e) => setTrait1(e.target.value)}
                    placeholder="Trait 1"
                />
            </div>
            <div>
                <label>Egenskap 2</label>
                <input
                    id='titleInput'
                    autoComplete=""
                    type="text"
                    value={trait2}
                    onChange={(e) => setTrait2(e.target.value)}
                    placeholder="Trait 2"
                />
            </div>
            <div>
                <label>Egenskap 3</label>
                <input
                    id='titleInput'
                    autoComplete=""
                    type="text"
                    value={trait3}
                    onChange={(e) => setTrait3(e.target.value)}
                    placeholder="Trait 3"
                />
            </div>
        </form>

      <Editor
          // toolbarOnFocus
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          placeholder='Text...'
          toolbar={{
              options: ['inline', 'textAlign', 'fontSize', 'history'],
              inline: { inDropdown: true,
                options: ['bold', 'italic', 'underline']
              },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: false },
              fontSize: { inDropdown: true,
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        }
            }}

        />
        <button onClick={() => updateAbout(user, convertedContent, trait1, trait2, trait3, username)}>Save</button>
    </TextEditorContainer>
  )
}

export default TextEditor;