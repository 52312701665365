import '../App.css'
import styled from 'styled-components'
import LoadingAnimation from '../Components/Loading'


// Import the functions you need from the SDKs you need
import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, query, where, orderBy} from "../Firebase/Firebase";

export const LogbookList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 99%;
    li {
        cursor: pointer;
        font-size: 1.1rem;
    }
    li:hover {
        font-size: 1.3rem;
    }
`


export const PublishedLogs = (props) => {
    const [collectionData, setCollectionData] = useState([]);
    const [user] = useState(props.user);
    
    useEffect(() => {
        const fetchCollection = async () => {
            
            // Specify the collection to fetch
            const collectionRef = collection(db, "logbooks", "pobbof", "logEntrieID")
            
            try {
                const q = query(collectionRef, where('published', '==', true), orderBy("index", "asc"));
                // Fetch the collection
                const querySnapshot = await getDocs(q)
                // Extract the documents from the collection
                const documents = querySnapshot.docs.map((doc) => [doc.id, doc.data()]);
        
                // Update the state with the fetched collection
                setCollectionData(documents);
                } catch (error) {
                console.error('Error fetching collection:', error);
                }
        };
        fetchCollection()
        }, [user]);

 
    return (
        <LogbookList>
        {collectionData.length ? (
            collectionData.map((document, index) => (
            <ul key={index}>
                <li  onClick={() => {props.setLogID(document[0]);}} >{document[1].title}</li>
            </ul>
            ))
        ) : (
            <LoadingAnimation/>
        )}
        </LogbookList>
    );
};


