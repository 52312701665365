import '../App.css'
import   styled                from 'styled-components'
import { Outlet, NavLink }        from "react-router-dom"
// import logoSVG from '../Data/Logo.svg'
import LoadingAnimation from '../Components/Loading'
import journalConfig from '../Data/journalConfig.json'
import { useState, useEffect } from 'react'
import { FaBars } from "react-icons/fa";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";
import { db, collection, getDocs, query, where,} from "../Firebase/Firebase";

export const NavBar = styled.nav`
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: 0;
    top: 0;
    background-color: var(--color);
    z-index:1000;
    transition: 0.5s;
    button {
        color: black;
        background: None;
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 8px;
        #menuBTN {
            margin-left: 2px;
            font-size: 25px;
        }
        p {
            margin: 0;
        }
        background: white;
        border: 1px solid black;
        border-radius: 16px;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
    }
`

export const NavProp = styled.div`
    width: 90%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin: 0 auto;
    li {
        color: white;
    }
    #signout {
        border-radius: 16px;
        width: 90px;
        position: absolute;
        bottom: 40px;
        left: 60%;
        text-align: center;
        font-size: 20px;
        color: red;
    }
`

export const Nav = styled.nav`
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 80px auto;
    width: 90%;
    a.active {
        color: red
    }
`
export const LinK = styled(NavLink)`
    margin: 10px auto;
    width: 250px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
`

export const Logo = styled.img`
    width: 35px;
    height: 35px;
`

const Layout = (props) => {
    const [showMenu, setShowMenu] = useState(0)
    const [chapter, setChapter] = useState(false)
    const [hide] = useState(props.hide)
    const [collectionData, setCollectionData] = useState([]);
    const [user] = useState(props.user);
    
    useEffect(() => {
        const fetchCollection = async () => {
            
            // Specify the collection to fetch
            const collectionRef = collection(db, "logbooks", "pobbof", "logEntrieID")
            
            try {
                const q = query(collectionRef, where('published', '==', true));
                // Fetch the collection
                const querySnapshot = await getDocs(q)
                // Extract the documents from the collection
                const documents = querySnapshot.docs.map((doc) => [doc.id, doc.data()]);
        
        
                // Update the state with the fetched collection
                setCollectionData(documents);
                } catch (error) {
                console.error('Error fetching collection:', error);
                }
        };
        fetchCollection()
        }, [user]);

    return (
        <div>
            <NavBar style={{width: `${showMenu}vw`, display: `${hide}`}}>
            { showMenu === 0 ?
                <button style={{border: "1px solid black"}} onClick={() => {setShowMenu(100)}}> 
                    <p>Meny</p> {<FaBars id='menuBTN'/>}
                </button>
            :   
                <button style={{border: "0.5px solid white", color: 'red', background: 'black'}} onClick={() => {setShowMenu(0)}}>
                    <p >Stäng</p> {<FiArrowRight id='menuBTN'/>}
                </button>
            }
                <h1 style={{color: 'white', textAlign: 'center'}}>Meny</h1>
                <Nav>
                    {
                        props.isLoggedIn 
                        ?   <>
                                <NavProp>
                                    {/* <Logo src={logoSVG} alt=''/> */}
                                    <LinK onClick={() => {setShowMenu(0); setChapter(false)}} to="/">{journalConfig[0].buttonsSWE.landingPageBTN}</LinK>
                                    <LinK onClick={() => {setShowMenu(0); setChapter(false)}} to="/About">{journalConfig[0].buttonsSWE.about}</LinK>
                                    <LinK onClick={() => {setShowMenu(0); setChapter(false)}} to="/UserPage">{journalConfig[0].buttonsSWE.userPage}</LinK>
                                    <LinK onClick={() => {setChapter(true)}} to="/Logbook">Välj Kappitel <FiArrowDown/> </LinK>
                                    { chapter &&
                                    <div>
                                        {collectionData.length ? (
                                            collectionData.map((document, index) => (
                                            <ul key={index}>
                                                <li onClick={() => {props.setLogID(document[0]); setShowMenu(0)}} >{document[1].title}</li>
                                            </ul>
                                            ))
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                    }
                                    <LinK id='signout' onClick={() => {setShowMenu(0)}} to="/SignOut">Logga ut</LinK>
                                </NavProp>
                            </> 
                        :   <>
                                <NavProp>
                                    {/* <Logo src={logoSVG} alt=''/> */}
                                    <LinK onClick={() => {setShowMenu(0); setChapter(false)}} to="/">{journalConfig[0].buttonsSWE.landingPageBTN}</LinK>
                                    <LinK onClick={() => {setShowMenu(0); setChapter(false)}} to="/About">{journalConfig[0].buttonsSWE.about}</LinK>
                                    <LinK onClick={() => {setChapter(true)}} to="/Logbook">Välj Kappitel <FiArrowDown/> </LinK>

                                    { chapter &&
                                    <div>
                                        {collectionData.length ? (
                                            collectionData.map((document, index) => (
                                            <ul  key={index}>
                                                <li  onClick={() => {props.setLogID(document[0]); setShowMenu(0)}} >{document[1].title}</li>
                                            </ul>
                                            ))
                                        ) : (
                                            <LoadingAnimation/>
                                        )}
                                    </div>
                                    }

                                </NavProp>
                            </>
                    }
                </Nav>
            </NavBar>
            <Outlet />
        </div>
    )
}

export default Layout