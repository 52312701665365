import '../App.css'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"
import journalConfig from '../Data/journalConfig.json'
import profile_815x1000 from "../Data/profile_815x1000.png"
import LoadingAnimation from '../Components/Loading'


import { db, doc, getDoc, collection, getDocs, where, query} from '../Firebase/Firebase';
import React, { useEffect, useState } from 'react';



export const HookComponent = styled.div`
  height: fit-contnet;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  #nameAtributes {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  img {
    display: block;
    width: 300px;
    height: auto;
    margin: -4% 0 0 18%;
  }
  p {
    margin: 0;
    padding: 0;
  }
  #name {
    position: relative;
    top: 50px;
    left: -60px;
    padding: 0;
    font-size: 4rem;
    margin: 0;
  }
  #atributes {
    position: relative;
    left: -95px;
    top: 50px;
    text-align: left;
    font-size: 1.3rem;
  }
  button {
    width: 100%;
  }
  #info {
    margin: 5px 0 40px 0;
  }
  #hookText {
    width: 80%;
    margin: 20px auto;
  }
`

export const Hook = (props) => {
    const [data, setData] = useState(null);
    const [showButton] = useState(props.showButton);
    const [showPostHook] = useState(props.showPostHook);
    const [hookText, setHookText] = useState('')
    const [id, setID] = useState('')
    const [title, setTitle] = useState('')

    const fetchData = async () => {
      try {
        const docRef = doc(db, 'users', 'pobbof');
        const docSnapshot = await getDoc(docRef);
        
        if (docSnapshot.exists) {
          setData(docSnapshot.data());
          console.log("Landing page hook Loaded");
        } else {
          console.log('Document does not exist');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };
    
    // Load hook text data
    const getHookText = async () => {
      
      // Specify the collection to fetch
      const collectionRef = collection(db, "logbooks", 'pobbof', "logEntrieID");
      
      try {
        // Fetch the collection
        const q = query(collectionRef, where('published', '==', true));
        const docSnapshot = await getDocs(q);
        
        // Extract the documents from the collection
        docSnapshot.docs.map((doc) => [
          setHookText(doc.data().hookText), 
          setTitle(doc.data().title), 
          setID(doc._key.path.segments[8])
        ]);

        
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };

    useEffect(() => {
      getHookText();
        fetchData();
      }, []);

    return (
      <>
        <HookComponent>
          { data ?
            <>
              <div id="nameAtributes">
                <div >
                    <h1 id="name">{data.username}</h1>
                </div>
                  <div id="atributes">
                    <p>{data.trait1}</p>
                    <p>{data.trait2}</p>
                    <p>{data.trait3}</p>
                  </div>
              </div>
              <img alt='' src={profile_815x1000}/>
              <div id='hookText'>
                {
                    showPostHook && 
                    <div  style={{margin: '0px 0 0 3px'}}>
                    <h2 style={{margin: '0'}}>{title}</h2>
                    <p id="info">{hookText}</p>
                    </div>
                }
                {
                    showButton &&
                    <div style={{margin: '0 auto', width: '60%'}}>
                        <NavLink to="/Logbook">
                            <button onClick={() => {props.setLogID(id)}} style={{cursor:"pointer"}}>{journalConfig[0].buttonsSWE.latestPost}</button>
                        </NavLink>
                    </div>
                }
              </div>
            </>
            : 
            <LoadingAnimation/>
          }
        </HookComponent>
      </>

    )
}