import '../App.css'
import styled from 'styled-components'
import React from 'react'
import { Hook } from './MobileHook'


export const MobileComponent = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
`
function Mobile(props) {
  return (
      <MobileComponent>
        <Hook setLogID={props.setLogID} showPostHook={true} showButton={true}/>
      </MobileComponent>
  )
}
export default Mobile