import '../App.css'

import styled from 'styled-components'

export const DisplayTextDesktop = styled.div`
  width: 70%;
    padding: 20px;
`

export const Desktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  width: 100vw;
  height: calc(100vh - var(--headerNavHeight));
  margin-top: var(--headerNavHeight);

  #logsListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
  }
  #logs {
    display: grid;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 10px;
    width: 60%;
    height: 40px;
    margin: 5px;
    grid-template-columns: 85% 15%;
  }
  label, li, p {
    margin: 0;
    padding: 0;
  
  }
  form {
    padding: 0 10px 0 10px;
    border-left: 1px solid black;
    display: flex;
    align-items: center;
    margin:0 auto;
  }
  #checkbox {
    width: 20px;
    height: 20px;
  }
  #about {
    display: grid;
    grid-template-columns: 40% 50%;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: fit-content;
  }
  #picture {
    width: 100%;
    text-align: center;
  }
  #editBtns {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
  }
  a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    padding-left: 10px;
  }
  #publish {
    border-left: 1px solid black;
    padding-left: 10px;
  }
`