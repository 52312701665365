import '../App.css'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"
import journalConfig from '../Data/journalConfig.json'
import LoadingAnimation from '../Components/Loading'


import { db, doc, getDoc, collection, getDocs, where, query} from '../Firebase/Firebase';
import React, { useEffect, useState } from 'react';

export const HookComponent = styled.div`
  height: fit-contnet;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  #nameAtributes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 400px;
    display: flex;
    bottom: 0;
    border-bottom: 5px solid black;
  }
  p {
    margin: 0;
    padding: 0;
  }
  #name {
    position: absolute;
    bottom: 0;
    width:fit-content;
    font-size: 4rem;
    margin-block-start: 0;
    margin-block-end: -9px;
  }

  #atributes {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1.3rem;
  }
  #hook {
    margin: 30px 0 0 3px;
    width: 400px;
  }
  button {
    width: 60%;
    margin: 0 auto
  }

  #info {
    margin: 5px 0 40px 0;
  }
`

export const Hook = (props) => {
    const [data, setData] = useState(null);
    const [showButton] = useState(props.showButton);
    const [showPostHook] = useState(props.showPostHook);
    const [hookText, setHookText] = useState('')
    const [title, setTitle] = useState('')
    // const [logID, setLogID] = useState(false);


    const fetchData = async () => {
      try {
        const docRef = doc(db, 'users', 'pobbof');
        const docSnapshot = await getDoc(docRef);
        
        if (docSnapshot.exists) {
          setData(docSnapshot.data());
          console.log("Landing page hook Loaded");
        } else {
          console.log('Document does not exist');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };
    
    // Load hook text data
    const getHookText = async () => {
      
      // Specify the collection to fetch
      const collectionRef = collection(db, "logbooks", 'pobbof', "logEntrieID");
      
      try {
        // Fetch the collection
        const q = query(collectionRef, where('published', '==', true));
        const docSnapshot = await getDocs(q);
        
        // Extract the documents from the collection
        docSnapshot.docs.map((doc) => [
          setHookText(doc.data().hookText), 
          setTitle(doc.data().title), 
        ]);

        
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };


    useEffect(() => {
      getHookText();
        fetchData();
      }, []);

    return (
      <>
        <HookComponent>
          { data ?
            <>
              <div id="nameAtributes">
                <div >
                    <h1 id="name">{data.username}</h1>
                </div>
                  <div id="atributes">
                    <p>{data.trait1}</p>
                    <p>{data.trait2}</p>
                    <p>{data.trait3}</p>
                  </div>
              </div>
              {
                showPostHook && 
                <div id="hook">
                  <h2 style={{margin: '0'}}>{title}</h2>
                  <p id="info">{hookText}</p>
                </div>
              }
              {
                showButton &&
                <NavLink style={{margin: "0 auto", width: "300px"}} to="/Logbook"  >
                  <button style={{cursor:"pointer"}}>{journalConfig[0].buttonsSWE.latestPost}</button>
                </NavLink>
              }
            </>
            : 
            <LoadingAnimation/>
          }
        </HookComponent>
      </>

    )
}