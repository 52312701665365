import '../App.css'
import { Desktop } from './Desktop'
import Mobile from './Mobile'
import { ProfilePicture } from './ProfilePicture'
import { Hook } from './DesktopHook'
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react'

 
function Landingpage(props) {
  const isMobileDevice = useMediaQuery("(max-device-width: 780px)");
  const isDesktopDevice = useMediaQuery("(min-device-width: 780px)");

  return (
    <>

      {isMobileDevice && 
        <Mobile setLogID={props.setLogID}/>
      }
      {isDesktopDevice && 
        <Desktop>
          <ProfilePicture width={'90%'}/>
          <Hook showPostHook={true} showButton={true}/>
        </Desktop>
      }
    </>
  )
}
export default Landingpage