import '../App.css'
import { Desktop } from './Desktop'
import { TextArea } from './TextArea'
import { PublishedLogs } from './PublishedLogs'


import { useState, useEffect } from 'react';
import { db, getDoc,getDocs, doc, collection, where, query } from "../Firebase/Firebase";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Mobile } from './Mobile'
// import { FiAlignLeft } from "react-icons/fi";

import React from 'react'

 
function Logbook(props) {
  const isMobileDevice = useMediaQuery("(max-device-width: 780px)");
  const isDesktopDevice = useMediaQuery("(min-device-width: 780px)");
    const [user] = useState(props.user);
    const [document, setDocument] = useState('')
    
    useEffect(() => {
      if (props.logID){
            const fetchCollection = async () => {
                // Specify the collection to fetch
                const docRef = doc(db, "logbooks", 'pobbof', "logEntrieID", `${props.logID}`);
                
                try {
                    // Fetch the collection
                    const docSnapshot = await getDoc(docRef);
                    
                    // Extract the documents from the collection
                    const documents = docSnapshot.data();
                    // Update the state with the fetched collection
                    setDocument(documents)
                } catch (error) {
                    console.error('Error fetching collection:', error);
                }
            };
            fetchCollection()
        } else {
          const fetchCollection = async () => {
            
            // Specify the collection to fetch
            const collectionRef = collection(db, "logbooks", 'pobbof', "logEntrieID");
            
            try {
              // Fetch the collection
              const q = query(collectionRef, where('published', '==', true, '&&', 'index', '==', 1));
              const docSnapshot = await getDocs(q);
              
              // Extract the documents from the collection
              docSnapshot.docs.map((doc) => [setDocument(doc.data())]);
      
              
            } catch (error) {
              console.error('Error fetching collection:', error);
            }
          };
          fetchCollection()

        }
    }, [props.logID, user]);
    console.log(document);

  return (
    <>
      {isMobileDevice && 
        <Mobile>
          <TextArea  marginTop="40px" textAlign="justify" document={document}/>
        </Mobile>
      }
      {isDesktopDevice && 
        <Desktop>
          <div></div>
          <TextArea marginTop="20px" document={document}/>
          <PublishedLogs  setLogID={props.setLogID} user={user}/>
        </Desktop>
      }
    </>
  )
}
export default Logbook