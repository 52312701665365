import React, { useState, useEffect } from 'react';
import '../App.css'
import styled from 'styled-components'
import journalConfig from '../Data/journalConfig.json'

import { db, getDoc, doc, savePost } from "../Firebase/Firebase";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.css';

export const TextEditorContainer = styled.div`
  width: 100vw;
  height: calc(100vh - var(--headerNavHeight));
  margin-top: var(--headerNavHeight);
  
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    label {
      font-size: 2rem
    }
    #titleInput {
      height: 2rem;
      font-size: 1.6rem;
      width: 400px;
    }
    #checkbox {
      height: 25px;
      width: 25px
    }
    #titleHook {
      justify-content: space-around;
      display: flex;
      width: 100%
    }
  }
  #savePublish {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    button {
      width: 400px;
      margin: 0 auto;
    }
  }
`

function TextEditor(props) {
  const [logID] = useState(props.logID);
  const [publish, setPublish] = useState(false);
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");
  const [hookText, setHookText] = useState("");
  const [convertedContent, setConvertedContent] = useState(null);
  let [lastSaved, setLastSaved] = useState(false);


  const handleCheckboxChange = (event) => {
    setPublish(event.target.checked);
  };

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  
  

  useEffect(() => {
    const fetchCollection = async () => {
      // Specify the collection to fetch
      const docRef = doc(db, "logbooks", 'pobbof', "logEntrieID", `${logID}`);
      try {
        // Fetch the collection
        const docSnapshot = await getDoc(docRef);
        
        // Extract the documents from the collection
        const documents = docSnapshot.data();


        const contentBlocks = htmlToDraft(documents.text);
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        // Set the converted content state to the Draft.js editor
        const editorState = EditorState.createWithContent(contentState);

        // Update the state with the fetched collection
        setEditorState(editorState)
        setPublish(documents.published)
        setTitle(documents.title)
        setIndex(documents.index)
        setHookText(documents.hookText)
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };
    fetchCollection()
  }, [logID]);


  useEffect(() => {
    let html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html);
  }, [editorState]);
  
  // console.log(user.uid);
  

  return (
    
    <TextEditorContainer>
        <form action="">
          <div id='titleHook'>
            <div>
              <label>Title</label>
              <br/>
              <input
                id='titleInput'
                autoComplete=""
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
            </div>
            <div>
              <label id='hookText'>Hook</label>
              <br/>
              <textarea
              rows="4" 
              cols="50"
              value={hookText}
              onChange={(e) => setHookText(e.target.value)}
              />
            </div>
          </div>

      <Editor
          // toolbarOnFocus
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          placeholder='Text...'
          toolbar={{
              options: ['inline', 'textAlign', 'fontSize', 'history', 'blockType', 'fontFamily'],
              inline: { inDropdown: false,
                options: ['bold', 'italic', 'underline']
              },
              list:       { inDropdown: true },
              textAlign:  { inDropdown: true },
              link:       { inDropdown: true },
              history:    { inDropdown: false },
              fontSize:   { inDropdown: true,
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,},
              blockType:  { inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,},
              fontFamily: { options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,},
            }}
        />

          <div style={{margin: '20px auto'}}>
            <label id='publish'>{journalConfig[0].buttonsSWE.publish}</label>
            <input
              id='checkbox'
              checked={publish}
              type="checkbox"
              onChange={handleCheckboxChange}
              placeholder="Password"
            />
          </div>
        </form>

        <div id="savePublish">
          <button onClick={() => savePost(
                                          logID, 
                                          title, 
                                          convertedContent, 
                                          index,  
                                          publish, 
                                          hookText,
                                          setLastSaved={setLastSaved}
                                          )}>
                                          {journalConfig[0].buttonsSWE.save}
          </button>
          <h4 style={{color: 'red'}}>{lastSaved ? `Senast sparat - ${lastSaved}` : 'Glöm inte att spara' }</h4>
        </div>
    </TextEditorContainer>
  )
}

export default TextEditor;