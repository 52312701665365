import '../App.css'
import   styled                from 'styled-components'
import { Outlet, NavLink }        from "react-router-dom"
import logoSVG from '../Data/Logo.svg'
import journalConfig from '../Data/journalConfig.json'

export const NavBar = styled.nav`
    position: absolute;
    width: 100%;
    height: var(--headerNavHeight);
    top: 0;
    background-color: var(--color);
    z-index:1000;
`

export const NavProp = styled.div`
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Nav = styled.nav`
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
    a.active {
        color: red
    }
`
export const LinK = styled(NavLink)`
    margin: 0 20px 0 20px;
    padding: 0;
    color: white;
    text-decoration: none;
`
export const Logo = styled.img`
    width: 35px;
    height: 35px;
`

const Layout = (props) => {

    return (
        <>

        <NavBar>
            <Nav>
                {
                    props.isLoggedIn 
                    ?   <>
                            <NavProp>
                                <Logo src={logoSVG} alt=''/>
                                <LinK to="/">{journalConfig[0].buttonsSWE.landingPageBTN}</LinK>
                                <LinK to="/About">{journalConfig[0].buttonsSWE.about}</LinK>
                                <LinK to="/Logbook">{journalConfig[0].buttonsSWE.logbook}</LinK>
                                <LinK to="/UserPage">{journalConfig[0].buttonsSWE.userPage}</LinK>
                            </NavProp>
                            <LinK to="/SignOut">Logga ut</LinK>
                        </> 
                    :   <>
                            <NavProp>
                                <Logo src={logoSVG} alt=''/>
                                <LinK to="/">{journalConfig[0].buttonsSWE.landingPageBTN}</LinK>
                                <LinK to="/About">{journalConfig[0].buttonsSWE.about}</LinK>
                                <LinK to="/Logbook">{journalConfig[0].buttonsSWE.logbook}</LinK>
                            </NavProp>
                                {/* <LinK to="/Login">Login</LinK> */}
                        </>
                }
            </Nav>
        </NavBar>
        <Outlet />
        </>
    )
}

export default Layout