// Dependencies
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingAnimation from './Components/Loading'

// Styling
import './App.css';
// Components
import NavigationDesktop from './NavigationDesktop/NavigationDesktop.js';
import NavigationMobile  from './NavigationMobile/NavigationMobile.js';

// Pages
import Landingpage  from './Landingpage/Landingpage.js';
import Login        from './Login/Login.js';
import Logbook      from './Logbook/Logbook.js';
import SignOut      from './Login/SignOut.js';
import UserPage     from './UserPage/UserPage.js';
import About        from './UserPage/About.js';
import AboutEditor  from './UserPage/AboutEditor.js';
import TextEditor   from './TextEditor/TextEditor.js';

import { 
  getAuth,
  onAuthStateChanged 
 } from 'firebase/auth';


function App() {
  const isDesktopDevice = useMediaQuery("(min-device-width: 780px)");

  const [logID, setLogID] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState('')
  const auth = getAuth();
  useEffect(() => {
      onAuthStateChanged(auth, (user) => {
      if (user) {
          setIsLoggedIn(true)
          setUser(user)

      } else {
          setIsLoggedIn(false)
      }
      setAuthLoaded(true)
      });
  })
  return (
    <>
      { authLoaded ?
        <BrowserRouter> 
          <Routes>
            <Route path="/" element={ isDesktopDevice ?
                <NavigationDesktop isLoggedIn={isLoggedIn}/>
                :
                <NavigationMobile setLogID={setLogID} user={user.uid} isLoggedIn={isLoggedIn} hide="block"/>
            }>
              
              <Route index              element={<Landingpage setLogID={setLogID}/>}/>
              <Route path="/Login"      element={<Login/>}/>
              <Route path="/Logbook"    element={<Logbook setLogID={setLogID} logID={logID} user={user.uid}/>}/>
              <Route path="/SignOut"    element={<SignOut/>}/>
              <Route path="/About"      element={<About user={user.uid}/>}/>
              <Route path="/AboutEditor"element={<AboutEditor user={user.uid}/>}/>
              <Route path="/UserPage"   element={<UserPage  isLoggedIn={isLoggedIn} 
                                                            user={user.uid}
                                                            setLogID={setLogID}
                                                            />}/>
              <Route path="/TextEditor" element={<TextEditor isLoggedIn={isLoggedIn} 
                                                             user={user.uid}
                                                             logID={logID}/>}/>
  
            </Route>
          </Routes>
        </BrowserRouter>
        :
        <LoadingAnimation/>
      }
    </>
  );
}

export default App;
