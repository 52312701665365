import '../App.css'
import styled from 'styled-components'

import DOMPurify from 'dompurify';
import LoadingAnimation from '../Components/Loading'

export const DisplayText = styled.div`
    padding: 30px;
`
export const Title = styled.h1`
    text-align: center;
    margin-bottom: 40px;
    font-family: Georgia, serif;
    letter-spacing: 2px;
`

export const TextArea = (props) => {

    function createMarkup(html) {
        return {
        __html: DOMPurify.sanitize(html)
        }
    }
    return (
        <>
            { props.document ?
                <div>
                    <DisplayText style={{textAlign: `${props.textAlign}`, marginTop: `${props.marginTop}`}} >
                    <Title>{props.document.title}</Title>
                    <p dangerouslySetInnerHTML={createMarkup(props.document.text)}></p>
                    </DisplayText>
                </div>
                :
                <LoadingAnimation/>
            }
        </>
    )
}