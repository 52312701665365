import '../App.css'
import styled from 'styled-components'
import profile_815x1000 from "../Data/profile_815x1000.png"

export const Img = styled.div`
  max-width: 500px;
  margin: 20px auto;
  img {
    height: auto;
  }
`

export const ProfilePicture = (props) => {
    return (
        <Img>
          <img style={{width: `${props.width}`}} alt='' src={profile_815x1000}/>
      </Img>
    )
}