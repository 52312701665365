import '../App.css'
import { Desktop, DisplayTextDesktop } from './Desktop'
import { Mobile, DisplayTextMobile } from './Mobile'
import LoadingAnimation from '../Components/Loading'
import useMediaQuery from '@mui/material/useMediaQuery';
import {TextArea} from '../Logbook/TextArea';

// Import the functions you need from the SDKs you need
import React, { useEffect, useState } from 'react';
import { db, doc, getDoc } from '../Firebase/Firebase';



const About = (props) => {
  const isMobileDevice = useMediaQuery("(max-device-width: 780px)");
  const isDesktopDevice = useMediaQuery("(min-device-width: 780px)");
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'logbooks', 'pobbof', 'logEntrieID', 'tJP5cZmTcfctu3e9yVbW');
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists) {
          setData(docSnapshot.data());
          console.log("Landing page hook Loaded");
        } else {
          console.log('Document does not exist');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };
    
    fetchData();
  }, []);

  return (
    <>
      { isMobileDevice && 
        <Mobile>
          <>
            { data ?
              <DisplayTextMobile>

                <TextArea  marginTop="40px" textAlign="justify" document={data}/>
              </DisplayTextMobile>
              : 
              <LoadingAnimation/>
            }
          </>
        </Mobile>
      }
      { isDesktopDevice && 
        <Desktop>
          <>
              { data ?
              <DisplayTextDesktop>
                
              <TextArea marginTop="20px" document={data}/>
              </DisplayTextDesktop>
                : 
                <LoadingAnimation/>
              }
          </>
        </Desktop>
      }
    </>
  );
};

export default About;

