import '../App.css'

import styled from 'styled-components'

export const Mobile = styled.div`

    width: 100vw;
    height: fit-content;
    margin: 20px auto 20px auto;
   
`