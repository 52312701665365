import '../App.css'

import styled from 'styled-components'

export const Desktop = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
  display: grid;
  grid-template-columns: 17% 60% 17%;
  grid-column-gap: 3%;

  width: 99%;
  height: calc(100vh - var(--headerNavHeight));
  margin-top: var(--headerNavHeight);


`